import { Injectable, signal, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { User } from '@shared/models/user.model';
import { ApiRestService } from '@core/services/api.rest.service';
import { jwtDecode } from 'jwt-decode';
import { UserRole } from '@shared/interfaces/user-role.interface';
import { DataTableService } from './data-table.service';
import { environment } from '@environments/environment';
import { VALID_USER_ROLES } from '@shared/interfaces/user-role.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly tokenKey = environment.tokenKey;

  private readonly $$userSignal = signal<User | null>(null);
  public readonly $userSignal: Signal<User | null> = this.$$userSignal;

  isRefreshing = false; // Track if a token refresh is in progress
  tokenRefreshed$ = new BehaviorSubject<boolean>(false); // Notify when the token is refreshed

  constructor(
    private readonly apiRestService: ApiRestService,
    private readonly router: Router,
    private dataTableService: DataTableService,
  ) {}

  private readonly $$invalidTokenStateMessage = signal<string | null>(null);
  public readonly $invalidTokenStateMessage: Signal<string | null> =
    this.$$invalidTokenStateMessage;

  setInvalidTokenStateMessage(processing: string | null) {
    this.$$invalidTokenStateMessage.set(processing);
  }

  login(email: string): Observable<void> {
    this.dataTableService.cleanAllTableStates();
    return this.apiRestService.postVoid('auth/login', { email });
  }

  clearLogState(): void {
    localStorage.removeItem(this.tokenKey);
    this.dataTableService.cleanAllTableStates();
    this.$$userSignal.set(null);
  }

  logout(): void {
    this.clearLogState();
    this.router.navigate(['/login']);
  }

  verifyLoginToken(
    token: string,
    role: UserRole,
    renew = false,
    partnerToken = null,
  ): Observable<User> {
    return this.apiRestService
      .post<User>(User, 'auth/verify-token', {
        token,
        role,
        renew,
        partnerToken,
      })
      .pipe(
        tap((user) => {
          if (user.role !== role) {
            throw new Error('User role mismatch');
          }
          // NOTE: localStorage setItem is done in auth.interceptor with jwt token from header, we may have received a partner token here
          this.$$userSignal.set(user);
          this.$$invalidTokenStateMessage.set(null);
          if (this.isRefreshing) {
            this.isRefreshing = false; // Reset the refresh state
            this.tokenRefreshed$.next(true); // Notify that the token has been refreshed
          }
        }),
        catchError((error) => {
          console.error('Error verifying token:', error);
          this.$$userSignal.set(null);
          this.clearLogState();
          this.isRefreshing = false; // Reset the refresh state on error
          this.tokenRefreshed$.next(false); // Notify that the refresh failed
          this.$$invalidTokenStateMessage.set('auth.invalidToken');
          // NOTE: for role specific error messages, do switch case on role and set message
          throw error;
        }),
      );
  }

  getValidPathRole(path: string | undefined): UserRole | undefined {
    if (!path) {
      return undefined;
    }
    return VALID_USER_ROLES.find((role) => path.includes(role));
  }

  async redirectByLoginStatus(): Promise<void> {
    try {
      let targetLink = '/login';
      const user = this.$userSignal();
      if (user) {
        // Redirect based on user role
        switch (user.role) {
          case 'admin':
            targetLink = '/mailing';
            break;
          case 'partner':
            targetLink = '/partner';
            break;
          case 'customer':
            targetLink = '/purchases';
            break;
        }
      }
      // NOTE: If no user is authenticated or role is invalid - redirect to login by default targetLink
      await this.router.navigate([targetLink]);
    } catch (error) {
      console.error('Error during user redirection:', error);
      this.router.navigate(['/login']);
    }
  }

  isTokenExpiringSoon(token: string): boolean {
    const decoded: { exp: number } = jwtDecode(token);
    if (decoded?.exp) {
      const expirationDate = new Date(decoded.exp * 1000);
      const now = new Date();
      const thresholdMinutes = 15; // Threshold for "expiring soon" in minutes
      const thresholdMilliseconds = thresholdMinutes * 60 * 1000;
      const diffTime = expirationDate.getTime() - now.getTime();
      const isExpiringSoon = diffTime < thresholdMilliseconds;
      return isExpiringSoon;
    }
    return true; // NOTE: If we can't determine the expiration, assume it needs renewal
  }

  isTokenExpired(token: string): boolean {
    const decoded: { exp: number } = jwtDecode(token);
    if (decoded?.exp) {
      const expirationDate = new Date(decoded.exp * 1000);
      return expirationDate < new Date();
    }
    return false;
  }

  decodeToken(token: string): User | null {
    try {
      const decodedToken: { exp: number; id: string; role: UserRole } =
        jwtDecode(token);
      return {
        id: decodedToken.id,
        role: decodedToken.role,
      } as unknown as User;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  getLocalStorageToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  initUserByStorage(): Observable<User | null> {
    const token = this.getLocalStorageToken();
    if (token && token !== 'null') {
      try {
        const tokenUser = this.decodeToken(token);
        const isTokenExpired = this.isTokenExpired(token);
        if (tokenUser && !isTokenExpired) {
          const tokenExpiringSoon = this.isTokenExpiringSoon(token);
          // NOTE: on page reload, currentUser will be null until the token is verified
          const currentUser = this.$userSignal();
          if (tokenExpiringSoon || !currentUser) {
            this.isRefreshing = true;
            return this.verifyLoginToken(
              token,
              tokenUser.role,
              tokenExpiringSoon,
            );
          }
          return of(currentUser);
        }
        if (tokenUser && isTokenExpired) {
          this.$$invalidTokenStateMessage.set('auth.invalidToken');
        }
      } catch (error) {
        console.error(
          'initUserByStorage: Error initializing user by storage.',
          error,
        );
      }
    }
    return of(null);
  }

  /**
   * Returns an observable that emits true when a token refresh completes successfully
   * or false if the refresh fails.
   */
  waitForTokenRefresh(): Observable<boolean> {
    return this.tokenRefreshed$.asObservable();
  }
}
